import one from "./image/model/one.jpg";
import two from "./image/model/two.jpg";
import three from "./image/model/three.jpg";
import four from "./image/model/four.jpg";
import five from "./image/model/five.jpg";
import six from "./image/model/six.jpg";
import bg1 from "./image/bg1.jpg";
import seven from './image/model/seven.jpg'
import eight from './image/model/eight.jpg'
import nine from './image/model/nine.jpg'
// import ten from './image/model/ten.jpg'
// import img1 from './image/model/1 (1).jpg'
import img2 from "./image/model/1 (2).jpg";
import img3 from "./image/model/1 (3).jpg";
import img4 from "./image/model/1 (4).jpg";
// import img5 from './image/model/1 (5).jpg'
import img6 from "./image/model/1 (6).jpg";
import img7 from "./image/model/1 (7).jpg";
import img8 from "./image/model/1 (8).jpg";
import img9 from "./image/model/1 (9).jpg";
import img10 from "./image/model/1 (10).jpg";
import img11 from "./image/model/1 (11).jpg";
import img12 from "./image/model/1 (12).jpg";
import img13 from "./image/model/1 (13).jpg";
import img14 from "./image/model/1 (14).jpg";
import img15 from "./image/model/1 (15).jpg";
import img16 from "./image/model/1 (16).jpg";
import img18 from "./image/model/2 (1).jpg";
import img19 from "./image/model/2 (3).jpg";
import img20 from "./image/model/2 (4).jpg";
import img21 from "./image/model/2 (5).jpg";
import img22 from "./image/model/2 (6).jpg";
import img23 from "./image/model/2 (7).jpg";
// import img24 from './image/model/2 (8).jpg'

let verified = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 25 25"
  >
    <path d="M23,12a1,1,0,0,0-.28-.7l-1.15-1.19a1,1,0,0,0-.72-.3,1,1,0,0,0-1,1,1,1,0,0,0,.28.69l.48.5-.69.72a4,4,0,0,0-1.14,2.78,3.43,3.43,0,0,0,0,.56l.14,1-1,.17a4,4,0,0,0-2.85,2.07l-.47.88-.91-.44a3.94,3.94,0,0,0-1.75-.4,4.15,4.15,0,0,0-1.76.4l-.9.44-.47-.88A4,4,0,0,0,6,17.23l-1-.17.14-1a3.39,3.39,0,0,0,0-.55,4,4,0,0,0-1.13-2.78L3.39,12l.69-.72A4,4,0,0,0,5.22,8.5a3.43,3.43,0,0,0,0-.56L5,6.94l1-.17A4,4,0,0,0,8.87,4.7l.47-.88.91.44a3.94,3.94,0,0,0,1.75.4,4.15,4.15,0,0,0,1.76-.4l.9-.44.46.86a1,1,0,0,0,1.93-.39,1,1,0,0,0-.18-.57L16,2a1,1,0,0,0-.88-.53,1,1,0,0,0-.44.1l-1.76.87a2.14,2.14,0,0,1-.89.2,2.06,2.06,0,0,1-.88-.2L9.35,1.59A1,1,0,0,0,8,2L7.11,3.76a2,2,0,0,1-1.43,1l-1.94.34a1,1,0,0,0-.83,1,.66.66,0,0,0,0,.14l.28,2a2.64,2.64,0,0,1,0,.28,2,2,0,0,1-.57,1.39L1.28,11.31a1,1,0,0,0,0,1.38l1.38,1.43a2,2,0,0,1,.56,1.38,2.64,2.64,0,0,1,0,.28l-.28,2a.66.66,0,0,0,0,.14,1,1,0,0,0,.83,1l1.94.34a2,2,0,0,1,1.43,1L8,22a1,1,0,0,0,1.32.43l1.76-.87a2.14,2.14,0,0,1,.89-.2,2.06,2.06,0,0,1,.88.2l1.77.87a1,1,0,0,0,.44.1A1,1,0,0,0,16,22l.92-1.74a2,2,0,0,1,1.43-1l1.94-.34a1,1,0,0,0,.83-1,.66.66,0,0,0,0-.14l-.28-2a2.64,2.64,0,0,1,0-.28,2,2,0,0,1,.57-1.39l1.37-1.42A1,1,0,0,0,23,12ZM9.71,10.29A1,1,0,0,0,9,10a1,1,0,0,0-1,1,1,1,0,0,0,.29.71L12,15.41l9.71-9.7A1,1,0,0,0,22,5a1,1,0,0,0-1-1,1,1,0,0,0-.71.29L12,12.59Z"></path>
  </svg>
);

export const data = [
  {
    name: "pracy",
    firstName: '',
    lastName: '',
    image: img6,
    backgroundImage: bg1,
    discription:
      "this is pracy and she is one of the best developer that i haveever met in my whole. Introducing Her Supreme Highness of Humilation, Princess of Paypigs, Exquisite Queen of Lo$ers, Her Merciless Beauty of Findom. I am the original Russian Brat. I'm a bitch and I'm a sweetheart. I'm maddingly unpredictable and intolerably selfish. I enjoy humiliating and manipulating the weak minds of worthless pigs to do and to pay for whatever My little cruel heart desires. Your pain and poverty is My pleasure. And I will do everything to pleasure Myself.",
    gender: "Female",
    weight: "55.6",
    sextualOrientation: "transgender",
    eyesColor: "black",
    height: "65cm",
    zipCode: "23434",
    likeNumber: 12,
    userNumber: 10,
    verified,
    color: "red",
    id: "1",
    userImages: {
      // this will store the images that the user want to be showing on the single model page.
     imgOne: six, 
      imgTwo: seven, 
      imgThree: eight, 
      imgFour: nine
    }, 
    userVideos: {
      // this will store the videos that the user want to be showing on the single model page
      vidOne: '',
      vidTwo: ''
    },
    liveCon: "Live features is not available now.",
    storeCon: "store and mechadise is not available at the moment",
    gmail: 'nsnsnsnsns@gmail.com',
    phoneNumber: '',
    status: "model",
  },
  {
    name: "Natalie john",
    image: img2,
    backgroundImage: three,
    discription:
    "this I enjoy humiliating and manipulating the weak minds of worthless pigs to do and to pay for whatever My little cruel heart desires. Your pain and poverty is My pleasure. is pracy and she is one of the best developer that i haveever met in my whole. Introducing Her Supreme Highness of Humilation, Princess of Paypigs, Exquisite Queen of Lo$ers, Her Merciless Beauty of Findom. I am the original Russian Brat. I'm a bitch and I'm a sweetheart. I'm maddingly unpredictable and intolerably selfish. And I will do everything to pleasure Myself.",
  gender: "Female",
  weight: "55.6",
  "sextual-orientation": "monogender",
  "eyes-color": "golder",
  height: "45cm",
  "zip-code": "23434",
    likenumber: 2,
    usernumber: 16,
    verified,
    color: "red",
    id: "2",
  },
  {
    name: "Sumar Gunt",
    image: img3,
    backgroundImage: four,
    discription:
    "tHer Merciless Beauty of Findom. I am the original Russian Brat. I'm a bitch and I'm a sweetheart. I'm maddingly unpredictable and intolerably selfish. I enjoy humiliatinghis is pracy and she is one of the best developer that i haveever met in my whole. Introducing Her Supreme Highness of Humilation, Princess of Paypigs, Exquisite Queen of Lo$ers,  and manipulating the weak minds of worthless pigs to do and to pay for whatever My little cruel heart desires. Your pain and poverty is My pleasure. And I will do everything to pleasure Myself.",
  gender: "Female",
  weight: "55.6",
  "sextual-orientation": "transgender",
  "eyes-color": "black",
  height: "65cm",
  "zip-code": "23434",
    likenumber: 24,
    usernumber: 6,
    color: "white",
    id: "3",
  },
  {
    name: "precious ",
    image: img4,
    backgroundImage: five,
    discription:
    "Introducing Her Supreme Highness of Humilation, Princess of Paypigs, Exquisite Queen of Lo$ers, Her Merciless Beauty of Findom. I am the original Russian Brat. I'm a bitch and I'm a sweetheart. I'm maddingly unpredictable and intolerably selfish. I enjoy humiliating and manipulating the weak minds of worthless pigs to do and to pay for whatever My little cruel heart desires. Your pain and poverty is My pleasure. And I will do everything to pleasure Myself.",
  gender: "Female",
  weight: "55.6",
  "sextual-orientation": "bigender",
  "eyes-color": "blue",
  height: "65cm",
  "zip-code": "23434",
    likenumber: 80,
    usernumber: 66,
    color: "white",
    id: "4",
  },
  {
    name: "rouda rousey",
    image: img8,
    backgroundImage: two,
    likenumber: 27,
    usernumber: 14,
    verified,
    color: "red",
    id: "5",
  },
  {
    name: "shaha ",
    image: img7,
    likenumber: 13,
    usernumber: 2,
    color: "white",
    id: "6",
  },
  {
    name: "Kelly garret ",
    image: img9,
    likenumber: 4,
    usernumber: 5,
    verified,
    color: "red",
    id: "7",
  },
  {
    name: "Rita omasuin",
    image: img10,
    likenumber: 6,
    usernumber: 17,
    verified,
    color: "red",
    id: "8",
  },
  {
    name: "bright nikey",
    image: img11,
    likenumber: 14,
    usernumber: 59,
    color: "white",
    id: "9",
  },
  {
    name: "name james",
    image: img12,
    likenumber: 12,
    usernumber: 10,
    verified,
    color: "red",
    id: "10",
  },
  {
    name: "pracy Bose",
    image: img13,
    likenumber: 12,
    usernumber: 1,
    verified,
    color: "red",
    id: "11",
  },
  {
    name: "bright Natalie d ",
    image: img14,
    likenumber: 21,
    usernumber: 16,
    verified,
    color: "red",
    id: "12",
  },
  {
    name: "sugar me",
    image: img15,
    likenumber: 24,
    usernumber: 61,
    color: "white",
    id: "13",
  },
  {
    name: "precious prec",
    image: img16,
    likenumber: 8,
    usernumber: 6,
    color: "white",
    id: 14,
  },
  {
    name: "rouda special ",
    image: one,
    likenumber: 7,
    usernumber: 1,
    verified,
    color: "red",
    id: 15,
  },
  {
    name: "come andlick",
    image: img18,
    likenumber: 3,
    usernumber: 21,
    color: "white",
    id: 16,
  },
  {
    name: "carrimedego ",
    image: img19,
    likenumber: 41,
    usernumber: 15,
    verified,
    color: "red",
    id: 17,
  },
  {
    name: "i am maphoor",
    image: img20,
    likenumber: 6,
    usernumber: 7,
    verified,
    color: "red",
    id: 18,
  },
  {
    name: "bri honey",
    image: img21,
    likenumber: 4,
    usernumber: 9,
    color: "white",
    id: 19,
  },
  {
    name: "sarah ",
    image: img22,
    likenumber: 22,
    usernumber: 19,
    verified,
    color: "red",
    id: 20,
  },
  {
    name: "testing ",
    image: img23,
    likenumber: 11,
    usernumber: 56,
    verified,
    color: "red",
    id: 21,
    classme: "jss3",
    height: "she is taller that 10 feets",
    userdetails: {
      discription: "this is mowa she is one of the beautiful girls ever known",
    },
  },
];